import { ImgSize, LangEnum, Post, WordpressPost, AcfFields } from './types';

export function iOSMac(): boolean {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
      'MacIntel'
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

export const formatPhone = (phone: string) => {
  return `${phone.substring(0, 3)} (${phone.substring(3, 6)}) ${phone.substring(
    6,
    8
  )}-${phone.substring(8, 10)}-${phone.substring(10, 14)}`;
};

export const detectMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
};

export const getImagePath = (p: Post, size: ImgSize) => {
  return p.image[size]?.source_url || p.image.default.source_url || '';
};

export function normalizePost(p: WordpressPost, lang: LangEnum): Post {
  return {
    date: p.date,
    id: p.id,
    title:
      lang === LangEnum.uk
        ? p.title.rendered
        : p.acf[`${lang}_title` as keyof AcfFields],
    status: p.status,
    content:
      lang === LangEnum.uk
        ? p.content.rendered
        : p.acf[`${lang}_content` as keyof AcfFields],
    image: p.better_featured_image
      ? {
          ...p.better_featured_image.media_details.sizes,
          default: { source_url: p.better_featured_image.source_url }
        }
      : null,
    description:
      lang === LangEnum.uk
        ? p.acf.description
        : p.acf[`${lang}_description` as keyof AcfFields]
  };
}
