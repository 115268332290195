/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
const { iOSMac, detectMobile } = require('./src/heleprs');

exports.onClientEntry = () => {
  if (!iOSMac() && !detectMobile()) {
    document.querySelector('body').classList.add('Scrollbar');
  }
};
