export interface CloudinaryEdge {
  node: CloudinaryImage;
}
export interface CloudinaryImage {
  id?: string;
  secure_url?: string;
  public_id: string;
}
export enum PortalEnum {
  CallModal = 'call-modal',
  Lift = 'lift',
  Notification = 'notification',
  SlideMenu = 'slideMenu',
  Viewer = 'viewer'
}

export interface MetaItem {
  name: string;
  content: string;
}

export enum LangEnum {
  uk = 'uk',
  ru = 'ru'
}

type PostStatus =
  | 'publish'
  | 'future'
  | 'draft'
  | 'pending'
  | 'private'
  | 'trash'
  | 'auto-draft'
  | 'inherit';

export interface AcfFields {
  ru_title: string;
  ru_content: string;
  description: string;
  ru_description: string;
}

export interface WordpressPost {
  date: string;
  id: number;
  content: {
    rendered: string;
  };
  title: {
    rendered: string;
  };
  better_featured_image: {
    media_details: {
      sizes: {
        [key in ImgSize]: {
          source_url: string;
        };
      };
    };
    source_url: string;
  } | null;
  featured_media: number;
  acf: AcfFields;
  status: PostStatus;
}

export interface Post {
  date: string;
  id: number;
  title: string;
  content: string;
  status: PostStatus;
  image:
    | {
        [key in ImgSize]: {
          source_url: string;
        };
      }
    | null;
  description: string;
}

export type ImgSize =
  | 'large'
  | 'medium'
  | 'medium_large'
  | 'thumbnail'
  | 'default';

export enum routesEnum {
  home = '/',
  langHome = '/:lang/',
  posts = '/posts/',
  langPosts = '/:lang/posts/',
  post = '/posts/:id/',
  langPost = '/:lang/posts/:id/'
}
